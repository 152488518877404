import { FC, MouseEvent } from 'react';
import { HiOutlineLockClosed } from '@react-icons/all-files/hi/HiOutlineLockClosed';
import classNames from 'classnames';

import { AddWantAuthModal } from 'components/model_detail/v2/AddWantAuthModal';
import { useModal } from 'context/modal/ModalContext';
import styles from 'styles/Auctions.module.scss';

type ViewBidSignUpProps = {
  isTextVisible?: boolean;
  isSmall?: boolean;
  color?: string;
};

export const ViewBidSignUp: FC<ViewBidSignUpProps> = ({ isTextVisible = true, isSmall, color = 'turquoise' }) => {
  const { closeModal, openModal } = useModal();

  const openAuthModal = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    openModal(<AddWantAuthModal closeModal={closeModal} />);
  };

  return (
    <div
      role='button'
      style={{ width: 'fit-content' }}
      onClick={openAuthModal}
      className={classNames('d-flex align-items-center h-100 position-relative', `text-${color}`, styles.signUpText, {
        'px-2 justify-content-center': !isTextVisible
      })}
    >
      <div className={classNames(styles.signUpBlur, `bg-${color}`)} />
      <HiOutlineLockClosed size={isSmall ? 12 : 14} />
      {isTextVisible && (
        <span
          className={classNames('ps-2', {
            'fs-12px': isSmall,
            'fs-12px fs-md-14px': !isSmall
          })}
        >
          SIGN UP
        </span>
      )}
    </div>
  );
};
