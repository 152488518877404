import { FC } from 'react';
import { BsHeart } from '@react-icons/all-files/bs/BsHeart';
import { BsTag } from '@react-icons/all-files/bs/BsTag';

import { AuthSlideout, AuthSlideoutItem } from 'components/common/AuthSlideout';
import { SlideoutCommonProps } from 'components/design-system/Slideout';

type AddWantAuthModalProps = SlideoutCommonProps;

export const AddWantAuthModal: FC<AddWantAuthModalProps> = ({ ...props }) => (
  <AuthSlideout {...props} contentClassName='d-flex flex-column w-100'>
    <div className='d-flex flex-column overflow-scroll'>
      <AuthSlideoutItem
        title='Meet your private watch concierge'
        description='Once you create an account, your personal concierge representative will reach out if you need anything'
        icon={<BsHeart />}
      />
      <AuthSlideoutItem
        title='First access to the best inventory'
        description='Once we know what you want, we can show you the best examples first and keep you updated on all the relevant price changes'
        icon={<BsTag />}
      />
    </div>
  </AuthSlideout>
);
