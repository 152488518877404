import { MdBolt } from '@react-icons/all-files/md/MdBolt';

export const AuctionBadgeTestIds = {
  container: 'auction-badge-container'
};

export const AuctionBadge = () => (
  <div
    data-testid={AuctionBadgeTestIds.container}
    className='position-absolute ps-2 pe-3 py-1 m-3 riforma-regular fs-12px text-white rounded-pill top-0 d-flex align-items-center gap-1'
    style={{
      background: '#2A8370',
      zIndex: 5
    }}
  >
    <MdBolt size='1.5em' />
    <span>Auction</span>
  </div>
);
