import { FC, PropsWithChildren } from 'react';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import classNames from 'classnames';

import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { Button } from 'components/design-system/Button';
import { Slideout, SlideoutCommonProps } from 'components/design-system/Slideout';

export type AuthSlideoutProps = {
  contentClassName?: string;
} & SlideoutCommonProps &
  PropsWithChildren;

export const AuthSlideout: FC<AuthSlideoutProps> = ({ children, contentClassName = '', ...props }) => (
  <Slideout
    {...props}
    titleClassName='mt-4'
    title={
      <div className='d-flex align-items-center'>
        <CloudinaryImage src='top-nav-logo.png' width={79} height={24} alt='Bezel Logo' />
      </div>
    }
  >
    <div className='riforma-regular d-flex flex-column justify-content-between align-items-center h-100 text-primary'>
      <div className='fs-48px fs-lg-56px line-height-56px line-height-lg-64px text-center'>
        <div>Unlock</div>
        <div>
          <span className='text-success bradford-regular-italic'>the best </span>
          <span>of</span>
        </div>
        <div>Bezel</div>
      </div>
      <div
        className={classNames('text-primary pt-4 justify-content-between d-flex flex-column h-100', contentClassName)}
      >
        {children}
        <div className='pt-3 ps-3 mb-5'>
          <Link href='/api/auth/signup'>
            <Button
              roundedClassName='border-radius-46'
              className='px-4 py-3 justify-content-between riforma-regular w-100'
              variant='success'
            >
              <span className='fs-16px'>Create a Bezel Account</span>
              <HiArrowNarrowRight size={20} className='position-absolute top-50 end-0 mx-3 translate-middle' />
            </Button>
          </Link>
          <div className='pb-3' />
          <div className='d-flex justify-content-center px-md-4 pb-2 fs-14px text-center w-100'>
            <Link href='/api/auth/login'>
              <span className='opacity-60'>I already have a Bezel account. </span>
              <span className='text-decoration-underline'>Log in</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Slideout>
);
