import 'swiper/css';
import 'swiper/css/pagination';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaAngleLeft } from '@react-icons/all-files/fa6/FaAngleLeft';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';
import SwiperCore, { Autoplay, Lazy, Pagination, Virtual } from 'swiper';
import { Swiper } from 'swiper/react';
import { SwiperProps } from 'swiper/react/swiper-react';
import { Swiper as SwiperType, SwiperOptions, VirtualOptions } from 'swiper/types';

import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import carouselStyles from 'styles/Carousel.module.scss';
import styles from 'styles/ModelCarousel.module.scss';
import { StyledComponent } from 'types';

export interface ArrowClassname {
  left: string;
  right: string;
}

export interface CarouselProps extends StyledComponent {
  slidesPerView?: number | 'auto';
  title?: React.ReactNode;
  swiperProps?: SwiperProps;
  children?: ({ showArrows }: { showArrows: boolean }) => React.ReactNode;
  arrowClassname?: ArrowClassname;
  showArrowGradient?: boolean;
  disableArrows?: boolean;
  virtual?: boolean;
}

const defaultSwiperProps: SwiperOptions = {
  spaceBetween: 10,
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  slidesPerGroupAuto: true
};

export const defaultPreRenderedVirtualSlidesAmount = 2;

export const defaultArrowClassname: ArrowClassname = {
  left: classNames('position-relative', carouselStyles['arrow-left']),
  right: classNames('position-relative', carouselStyles['arrow-right'])
};

export const Carousel: React.FC<CarouselProps> = ({
  slidesPerView = 'auto',
  className,
  title,
  children,
  arrowClassname,
  swiperProps = {},
  virtual,
  disableArrows = false,
  showArrowGradient = true
}) => {
  SwiperCore.use([Lazy]);

  const { isDark, isDefault } = useTheme();
  const { isMobile, isTabletOrMobile } = useViewport();

  const [swiper, setSwiper] = useState<SwiperType>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const showArrows = !disableArrows && !isTabletOrMobile;

  if (swiper) {
    swiper.allowTouchMove = isTabletOrMobile;
  }

  const incrementIdx = useCallback(() => swiper?.slideNext(), [swiper]);
  const decrementIdx = useCallback(() => swiper?.slidePrev(), [swiper]);

  /**
   * I had to do something hacky here. React doesn't seem to track changes to swiper.isBeginning and swiper.isEnd
   * So the arrows weren't re-rendering when scrolling
   */
  useEffect(() => {
    setShowLeftArrow(showArrows && !swiper?.isBeginning);
    setShowRightArrow(showArrows && !swiper?.isEnd);
  }, [swiper?.isBeginning, swiper?.isEnd, showArrows]);

  const updateArrows = useCallback(() => {
    setShowLeftArrow(showArrows && !swiper?.isBeginning);
    setShowRightArrow(showArrows && !swiper?.isEnd);
  }, [swiper, showArrows]);

  const swiperModules = useMemo(() => [Pagination, Autoplay, Virtual], []);

  const defaultSwiperVirtualOptions: VirtualOptions = {
    cache: true,
    ...(isMobile && {
      addSlidesAfter: defaultPreRenderedVirtualSlidesAmount,
      addSlidesBefore: defaultPreRenderedVirtualSlidesAmount
    })
  };

  return (
    <div className={className}>
      {title && (
        <div className={`fs-18px riforma-medium ${isDark ? 'text-light90' : 'text-primary'} px-4`}>{title}</div>
      )}
      <div className='d-flex align-items-center w-100 position-relative'>
        {showLeftArrow && (
          <div
            className={classNames('d-flex h-100 position-absolute start-0 opacity-100', { 'w-5': showArrowGradient })}
            style={{ zIndex: '2' }}
            role='button'
            onClick={decrementIdx}
          >
            <div
              className={classNames('h-100 d-flex', defaultArrowClassname.left, arrowClassname?.left, {
                'bg-light': isDefault,
                'bg-darkTheme': isDark
              })}
              style={{
                backgroundColor: 'transparent !important'
              }}
            >
              <div className={`px-2 align-self-center ${isDark ? 'text-light60' : 'text-primary'}`}>
                <FaAngleLeft />
              </div>
            </div>
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  isDefault && styles.gradientRight,
                  isDark && styles.darkGradientRight,
                  carouselStyles['gradient-right']
                )}
              />
            )}
          </div>
        )}
        <Swiper
          lazy
          virtual={virtual ? defaultSwiperVirtualOptions : undefined}
          {...defaultSwiperProps}
          {...swiperProps}
          modules={swiperModules}
          slidesPerView={slidesPerView}
          onSwiper={setSwiper}
          onSlideChange={updateArrows}
          onReachBeginning={updateArrows}
          onReachEnd={updateArrows}
        >
          {children?.({ showArrows })}
        </Swiper>
        {showRightArrow && (
          <div
            role='button'
            onClick={incrementIdx}
            className={classNames('d-flex h-100 position-absolute end-0 opacity-100', {
              'w-5': showArrowGradient
            })}
            style={{ zIndex: '2' }}
          >
            {showArrowGradient && (
              <div
                className={classNames(
                  'h-100 w-100 position-relative',
                  isDefault && styles.gradientLeft,
                  isDark && styles.darkGradientLeft,
                  carouselStyles['gradient-left']
                )}
              />
            )}
            <div
              className={classNames('h-100 d-flex align-self-end', defaultArrowClassname.right, arrowClassname?.right, {
                'bg-light': isDefault,
                'bg-darkTheme': isDark
              })}
              style={{
                backgroundColor: 'transparent !important'
              }}
            >
              <div className={`px-2 align-self-center ${isDark ? 'text-light60' : 'text-primary'}`}>
                <FaAngleRight />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
